import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
console.log(process.env, "All environment variables");
console.log(process.env.REACT_APP_PATH_API, "API URL");
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
