import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import "./style.css";
import CaseModal from "../../Components/CaseModal/CaseModal";
import {
  AddCommentOutlined,
  Delete,
  DeleteOutline,
  EditOutlined,
  FlagOutlined,
  RemoveRedEyeOutlined,
  Search,
  ShareOutlined,
  VerifiedOutlined,
} from "@mui/icons-material";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Checkbox,
  Pagination,
  Stack,
  PaginationItem,
  Button,
} from "@mui/material";
import Swal from "sweetalert2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SlideArchive from "../Slide/SlideArchive";

import { API_URL } from "../../env";
import ShareModal from "../../Components/Share/ShareModal";

const WorkList = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [cases, setCases] = useState([]);
  const [open, setOpen] = useState(false);
  const [openWorklist, setopenWorklist] = useState(false);
  const [shareModal, setShareModal] = useState(false);

  const [selectedCases, setSelectedCases] = useState([]);

  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentCaseId, setCurrentCaseId] = useState(null);
  const [isDeleteActive, setIsDeleteActive] = useState(false);

  const getPriorityLabel = (value) => {
    let label = "Unknown";
    let style = {};

    switch (value) {
      case "1":
        label = "Low";
        style = {
          color: "var(--Green-100, #47D273)",
          fontSize: "14px",
          fontWeight: 600,
          borderRadius: "4px",
          background: "var(--Green-5, #EBFAEF)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: 68,
          height: 28,
        };
        break;
      case "2":
        label = "Medium";
        style = {
          color: "var(--Yellow-100, #FAC20A)",
          fontFamily: "Roboto",
          fontSize: "14px",
          fontWeight: 600,
          borderRadius: "4px",
          background: "var(--Yellow-5, #FEF9E6)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: 68,
          height: 28,
        };
        break;
      case "3":
        label = "High";
        style = {
          color: "var(--Red-100, #FB3B42)",
          fontFamily: "Roboto",
          fontSize: "14px",
          fontWeight: 600,
          borderRadius: "4px",
          background: "var(--Red-5, #FEE6E7)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: 68,
          height: 28,
        };
        break;
      default:
        break;
    }

    return { label, style };
  };

  const deleteCase = async (caseId) => {
    try {
      const response = await fetch(`${API_URL}cases/${caseId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.ok) {
        const result = await response.json();
        console.log(result.message);
        setRefresh(true);
        return true;
      } else {
        console.error("Failed to delete case");
        return false;
      }
    } catch (error) {
      console.error("Error deleting case:", error);
      return false;
    }
  };

  const handleDeleteCase = async (caseId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const isDeleted = await deleteCase(caseId);
        if (isDeleted) {
          Swal.fire("Deleted!", "Your case has been deleted.", "success");
        }
        setRefresh(!refresh);
      }
    });
  };

  useEffect(() => {
    fetch(`${API_URL}cases/?page=${page}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCases(data.cases);
        setTotalPages(data.total_pages);
      })
      .catch((error) => {
        console.error("Error fetching cases:", error);
      });
  }, [refresh, page]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSpecializationChange = (e) => {
    setSpecialization(e.target.value);
  };

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  const handleCreateCase = () => {
    setOpen(true);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedCases(cases?.map((caseItem) => caseItem.CaseId));
      setIsDeleteActive(true);
    } else {
      setSelectedCases([]);
      setIsDeleteActive(false);
    }
  };

  const handleSelectCase = (CaseId) => {
    setSelectedCases((prevSelected) => {
      const updatedSelection = prevSelected.includes(CaseId)
        ? prevSelected.filter((id) => id !== CaseId)
        : [...prevSelected, CaseId];

      setIsDeleteActive(updatedSelection.length > 0);
      return updatedSelection;
    });
  };

  const isCaseSelected = (CaseId) => selectedCases.includes(CaseId);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRowDoubleClick = (caseId) => {
    setCurrentCaseId(caseId);
    setopenWorklist(true);
  };
  const shareModalClick = () => {
    setShareModal(true);
  };
  const handleViewDetailsClick = (caseId) => {
    setCurrentCaseId(caseId);
    setopenWorklist(true);
  };

  const handleBatchDelete = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to delete multiple cases. This action cannot be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete them!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(`${API_URL}cases/batch_delete`, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              case_ids: selectedCases.map((id) => parseInt(id, 10)),
            }),
          });

          if (response.ok) {
            Swal.fire(
              "Deleted!",
              "The selected cases have been deleted.",
              "success"
            );
            setSelectedCases([]);
            setIsDeleteActive(false);
            setRefresh(!refresh);
          } else {
            throw new Error("Failed to delete cases");
          }
        } catch (error) {
          console.error("Error deleting cases:", error);
          Swal.fire(
            "Error",
            "An error occurred while deleting the cases.",
            "error"
          );
        }
      }
    });
  };

  const formatDateToUserTimeZone = (utcDateString) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get the user's time zone
    const zonedDate = toZonedTime(utcDateString, userTimeZone); // Convert to user's time zone
    return format(zonedDate, "MMMM do, yyyy h:mm:ss a"); // Format the date
  };

  return (
    <>
      {openWorklist ? (
        <SlideArchive
          setopenWorklist={setopenWorklist}
          openWorklist={openWorklist}
          currentCaseId={currentCaseId}
        />
      ) : (
        <div className="worklist-container">
          <div className="worklist-header">
            <div className="header-title">Worklist</div>
            <div className="filterBox">
              <div className="search-worklist">
                <input
                  style={{ border: "none" }}
                  className="search-bar"
                  type="text"
                  placeholder="Search by hospital no./biopsy no./SI no."
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <div>
                  <Search />
                </div>
              </div>
              <div className="text-div">
                From
                <input
                  className="date-worklist"
                  type="date"
                  value={fromDate}
                  onChange={handleFromDateChange}
                />
              </div>
              <div className="text-div">
                To
                <input
                  className="date-worklist"
                  type="date"
                  value={toDate}
                  onChange={handleToDateChange}
                />
              </div>
              <div>
                <select
                  className="specialization"
                  value={specialization}
                  onChange={handleSpecializationChange}
                >
                  <option value="">Specialization</option>
                  <option value="specialization1">Specialization 1</option>
                  <option value="specialization2">Specialization 2</option>
                  <option value="specialization3">Specialization 3</option>
                </select>
              </div>
              <div className="create-case" onClick={handleCreateCase}>
                Create Case
              </div>
            </div>
          </div>
          <div className="worklist-head">
            <div className="create-case-desktop" onClick={handleCreateCase}>
              Create Case
            </div>
            <div
              className="delete-btn"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Button
                onClick={handleBatchDelete}
                disabled={!isDeleteActive}
                startIcon={<Delete fontSize="small" />}
                style={{ color: isDeleteActive ? "red" : "inherit" }}
              >
                <div>Delete</div>
              </Button>
            </div>
          </div>
          <div className="table-container">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      padding: "0px 0px 5px 0px",
                      fontSize: 12,
                      fontWeight: "600",
                      borderBottom: "none",
                    }}
                  >
                    <Checkbox
                      className="small-checkbox"
                      indeterminate={
                        selectedCases.length > 0 &&
                        selectedCases.length < cases.length
                      }
                      checked={
                        cases.length > 0 &&
                        selectedCases.length === cases.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell className="table-head">Sl No</TableCell>
                  <TableCell className="table-head">Hospital Name</TableCell>
                  <TableCell className="table-head">Case Name</TableCell>
                  <TableCell className="table-head">Specialization</TableCell>
                  <TableCell className="table-head">Patient Details</TableCell>
                  <TableCell className="table-head">Date</TableCell>
                  <TableCell className="table-head">Total Files</TableCell>
                  <TableCell className="table-head">Total Size</TableCell>
                  <TableCell className="table-head">Priority</TableCell>
                  <TableCell
                    style={{
                      padding: "0px 5px 5px 0px",
                      textAlign: "end",
                      fontSize: 12,
                      fontWeight: "600",
                      borderBottom: "none",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cases?.map((row, index) => (
                  <>
                    <TableRow
                      className="table-row"
                      key={index}
                      onDoubleClick={() => handleRowDoubleClick(row.CaseId)}
                    >
                      <TableCell
                        style={{
                          borderTopLeftRadius: 12,
                          borderBottomLeftRadius: 12,
                        }}
                        className="table-cell"
                      >
                        <Checkbox
                          className="small-checkbox"
                          checked={isCaseSelected(row.CaseId)}
                          onChange={() => handleSelectCase(row.CaseId)}
                        />
                      </TableCell>
                      <TableCell className="table-cell">
                        {index + 1 + (page - 1) * 5}
                      </TableCell>
                      <TableCell className="table-cell">
                        {row.HospitalName}
                      </TableCell>
                      <TableCell className="table-cell">
                        {row.CaseName}
                      </TableCell>
                      <TableCell className="table-cell">
                        {row.Specialization}
                      </TableCell>
                      <TableCell className="table-cell">
                        <div>
                          <span className="details">Name</span>:{" "}
                          {row.PatientName}
                        </div>
                        <div>
                          <span className="details">Age:</span> {row.Age}
                        </div>
                        <div>
                          <span className="details">Gender:</span> {row.Gender}
                        </div>
                        <div>
                          <span className="details">Id:</span>{" "}
                          {row.PatientStrId}
                        </div>
                      </TableCell>
                      <TableCell className="table-cell">
                        {formatDateToUserTimeZone(row.CreatedAt)}{" "}
                      </TableCell>
                      <TableCell className="table-cell">{row.Slides}</TableCell>
                      <TableCell className="table-cell">
                        {parseFloat(row.FileSize).toFixed(2) + " Mb"}
                      </TableCell>
                      <TableCell className="table-cell">
                        <div
                          className="priority-level"
                          style={
                            getPriorityLabel(row.Priority.toString()).style
                          }
                        >
                          {getPriorityLabel(row.Priority.toString()).label}
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          borderTopRightRadius: 12,
                          borderBottomRightRadius: 12,
                          textAlign: "end",
                          width: 230,
                        }}
                        className="table-cell"
                      >
                        <IconButton className="iconButton">
                          <VerifiedOutlined className="verifiedIcon" />
                        </IconButton>
                        <IconButton className="iconButton">
                          <FlagOutlined className="flagIcon" />
                        </IconButton>
                        <IconButton className="iconButton">
                          <AddCommentOutlined className="addCommentIcon" />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            shareModalClick();
                          }}
                          className="iconButton"
                        >
                          <ShareOutlined className="shareIcon" />
                        </IconButton>

                        <IconButton
                          className="iconButton"
                          onClick={() => handleViewDetailsClick(row.CaseId)}
                        >
                          <RemoveRedEyeOutlined className="removeRedEyeIcon" />
                        </IconButton>
                        <IconButton className="iconButton">
                          <EditOutlined className="editIcon" />
                        </IconButton>
                        <IconButton
                          className="iconButton"
                          onClick={() => handleDeleteCase(row.CaseId)}
                        >
                          <DeleteOutline className="deleteIcon" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <div style={{ height: "4px" }}></div>
                  </>
                ))}
              </TableBody>
            </Table>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Stack spacing={2}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                )}
              />
            </Stack>
          </div>
          {open && (
            <CaseModal
              refresh={refresh}
              setRefresh={setRefresh}
              open={open}
              setOpen={setOpen}
            />
          )}
          {shareModal && (
            <ShareModal
              onClose={() => setShareModal(false)}
              shareModal={shareModal}
            />
          )}
        </div>
      )}
    </>
  );
};

export default WorkList;
