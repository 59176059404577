import React from "react";
import { useForm, Controller } from "react-hook-form";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import "./style.css";
import { API_URL } from "../../env";

const specializations = [
  { value: "Cardiology", label: "Cardiology" },
  { value: "Neurology", label: "Neurology" },
  { value: "Orthopedics", label: "Orthopedics" },
  { value: "Cytology", label: "Cytology" },
  { value: "Histopathology", label: "Histopathology" },
  { value: "Hematology", label: "Hematology" },
  { value: "Clinical pathology", label: "Clinical pathology" },
  { value: "Molecular pathology", label: "Molecular pathology" },
  { value: "Microbiology", label: "Microbiology" },
  { value: "Biochemistry", label: "Biochemistry" },
  { value: "Veterinary pathology", label: "Veterinary pathology" },
];
const priority = [
  { value: "1", label: "Low" },
  { value: "2", label: "Medium" },
  { value: "3", label: "High" },
];
const gender = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Others", label: "Others" },
];

export default function CaseModal({ open, setOpen, setRefresh }) {
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      CaseName: "",
      CaseDescription: "",
      HospitalName: "",
      CaseNo: "",
      Specialization: "",
      Site: "",
      PatientName: "",
      PatientStrId: "",
      Age: "",
      Gender: "",
      AssignTo: "",
      Priority: "",
      Status: true,
      MedicalHistory: "",
    },
  });

  const [users, setUsers] = React.useState([]);

  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    fetch(`${API_URL}cases/getusers`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setUsers(data);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, []);

  const textFieldStyles = {
    backgroundColor: "white",
    borderRadius: "12px",
    border: "none",
    height: "40px",
    "& .MuiInputBase-root": {
      height: "100%",
    },
  };
  const onSubmit = (formData) => {
    // Convert Age to integer before submitting
    const submissionData = {
      ...formData,
      Age: formData.Age ? parseInt(formData.Age, 10) : null,
    };

    fetch(`${API_URL}cases`, {
      method: "POST",
      body: JSON.stringify(submissionData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        reset();
        setRefresh((prev) => !prev);
        handleClose();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const renderTextField = (
    name,
    label,
    type = "text",
    select = false,
    options = []
  ) => (
    <Grid item xs={6}>
      <label className="label">{label}*</label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField
            fullWidth
            type={type}
            variant="outlined"
            sx={textFieldStyles}
            select={select}
            {...field}
            required
          >
            {select &&
              options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </TextField>
        )}
      />
    </Grid>
  );

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className="modal-box" sx={{ p: 4 }}>
            <Typography
              style={{ textAlign: "center", marginBottom: 20 }}
              id="transition-modal-title"
              variant="h5"
              component="h2"
            >
              Create New Case
            </Typography>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid
                style={{ height: 422, overflow: "auto" }}
                container
                spacing={2}
              >
                {renderTextField("CaseName", "Case Name")}
                {renderTextField("CaseNo", "Case No")}
                <Grid item xs={12}>
                  <label className="label">Case Description*</label>
                  <Controller
                    name="CaseDescription"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        variant="outlined"
                        sx={{ ...textFieldStyles, height: "auto" }}
                        rows={1}
                        {...field}
                        required
                      />
                    )}
                  />
                </Grid>
                {renderTextField("HospitalName", "Hospital Name")}
                {renderTextField("MedicalHistory", "Medical History", "string")}
                {renderTextField(
                  "AssignTo",
                  "Assign To",
                  "text",

                  true,
                  users.map((user) => ({
                    value: user.UserId,
                    label: user.FullName,
                  }))
                )}
                {renderTextField(
                  "Specialization",
                  "Specialization",
                  "text",
                  true,
                  specializations
                )}
                {renderTextField("PatientName", "Patient Name")}
                {renderTextField("Age", "Age", "number")}
                {renderTextField("Gender", "Gender", "text", true, gender)}
                {renderTextField("PatientStrId", "Patient Id")}
                {renderTextField("Site", "Site")}
                {renderTextField(
                  "Priority",
                  "Priority",
                  "text",
                  true,
                  priority
                )}
              </Grid>
            </Box>
            <div className="submit-div" style={{}}>
              <div className="cancel-div" onClick={handleClose}>
                Cancel
              </div>
              <div className="save-div" onClick={handleSubmit(onSubmit)}>
                Save
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
