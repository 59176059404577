import React from "react";
import "./style.css";
import { ContrastOutlined } from "@mui/icons-material";

const ContrastPanel = ({
  contrast = 1,
  brightness = 1,
  onContrastChange,
  onBrightnessChange,
  onReset,
  viewerRef,
}) => {
  const contrastPresets = {
    veryLow: 0.5,
    low: 0.75,
    normal: 1,
    high: 1.25,
    veryHigh: 1.5,
  };

  const safeNumber = (value, defaultValue = 1) => {
    return typeof value === "number" && !isNaN(value) ? value : defaultValue;
  };

  const formatNumber = (value) => {
    return safeNumber(value).toFixed(1);
  };

  return (
    <div className="contrast-panel">
      <div className="contrast-panel-header">
        <h3 className="contrast-panel-title">Image Adjustment</h3>
      </div>

      <div className="contrast-presets">
        {Object.entries(contrastPresets).map(([name, value]) => (
          <button
            key={name}
            className={`contrast-preset-btn ${
              safeNumber(contrast) === value ? "active" : ""
            }`}
            onClick={() => onContrastChange(value)}
          >
            {name
              .replace(/([A-Z])/g, " $1")
              .replace(/^./, (str) => str.toUpperCase())}
          </button>
        ))}
      </div>

      <div className="contrast-slider-section">
        <label className="contrast-slider-label">Contrast</label>
        <input
          type="range"
          min="0.5"
          max="2"
          step="0.1"
          value={safeNumber(contrast)}
          onChange={(e) => onContrastChange(parseFloat(e.target.value))}
          className="contrast-slider"
        />
        <div className="contrast-value">Value: {formatNumber(contrast)}x</div>
      </div>

      <div className="contrast-slider-section">
        <label className="contrast-slider-label">Brightness</label>
        <input
          type="range"
          min="0.5"
          max="2"
          step="0.1"
          value={safeNumber(brightness)}
          onChange={(e) => onBrightnessChange(parseFloat(e.target.value))}
          className="contrast-slider"
        />
        <div className="contrast-value">Value: {formatNumber(brightness)}x</div>
      </div>

      <button className="reset-btn" onClick={onReset}>
        Reset to Default
      </button>
    </div>
  );
};

// Add ContrastTool component for the toolbar icon
export const ContrastTool = ({ showPanel, setShowPanel }) => {
  return (
    <div
      className={`contrast-tool ${showPanel ? "active" : ""}`}
      onClick={() => setShowPanel(!showPanel)}
      title="Adjust Contrast & Brightness"
    >
      <ContrastOutlined />
    </div>
  );
};

export default ContrastPanel;
