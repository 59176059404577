import React, { useEffect, useRef, useState } from "react";
import OpenSeadragon from "openseadragon";
import { ShapeNames } from "openseadragon-select-plugin";
import { EllipseShape } from "./ellipseShape";
import { LineShape } from "./lineShape";
import html2canvas from "html2canvas";
import "./style.css";
import {
  RectangleOutlined,
  ContrastOutlined,
  Grid4x4Outlined,
  DeleteOutline,
  PolylineOutlined,
  FitScreen,
  PhotoCamera,
  RotateRight,
  RotateLeft,
  HighlightAlt,
} from "@mui/icons-material";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Pencil from "@mui/icons-material/Create";
import { Circle, Minus } from "lucide-react";
import { API_URL } from "../env";
import ContrastPanel from "./ContrastPanel";

const MicroscopeSlideViewer = ({
  currentImageUrl,
  setOpenWorkspace,
  openWorkspace,
  currentSlideId,
}) => {
  const [isSelecting, setIsSelecting] = useState(false);
  const [selectionObj, setSelectionObj] = useState(null);
  const [error, setError] = useState(null);
  const [annotations, setAnnotations] = useState({ annotations: [] });
  const [isViewerReady, setIsViewerReady] = useState(false);
  const [selectedAnnotation, setSelectedAnnotation] = useState(null);
  const [selectedAnnotationData, setSelectedAnnotationData] = useState(null);
  const [currentZoom, setCurrentZoom] = useState(0.5);
  const [lineWidth, setLineWidth] = useState("normal");
  const [drawColor, setDrawColor] = useState("#FF0000");
  const [labelInput, setLabelInput] = useState("");
  const [selectedShape, setSelectedShape] = useState(null);
  const [isSelectionActive, setSelectionActive] = useState(false);
  const [isCapturing, setIsCapturing] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [contrast, setContrast] = useState(1);
  const [brightness, setBrightness] = useState(1);
  const [showContrastPanel, setShowContrastPanel] = useState(false);
  const [activeTool, setActiveTool] = useState(null);

  const viewerRef = useRef(null);
  const btnRef = useRef(null);
  const shapeNames = ShapeNames;

  useEffect(() => {
    const initializeViewer = async () => {
      try {
        const viewer = OpenSeadragon({
          id: "viewer",
          prefixUrl: "https://openseadragon.github.io/openseadragon/images/",
          tileSources: `${API_URL}tile-server/s3-file/${currentImageUrl}`,
          showNavigator: true,
          navigatorPosition: "TOP_RIGHT",
          showZoomControl: true,
          showHomeControl: false,
          showFullPageControl: false,
          animationTime: 0.5,
          minZoomLevel: 0.5,
          maxZoomLevel: 30,
          crossOriginPolicy: "Anonymous",
          loadTilesWithAjax: true,
          gestureSettingsMouse: {
            clickToZoom: false,
            dblClickToZoom: false,
          },
          ajaxHeaders: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        viewerRef.current = viewer;

        viewer.addHandler("open", function () {
          setIsViewerReady(true);
        });

        viewer.addHandler("open-failed", function (event) {
          console.error("Failed to open image:", event);
          setError("Failed to load the image. Please check the image source.");
        });

        viewer.addHandler("canvas-click", function (event) {
          if (!event.quick) return;

          const clickTarget = event.originalEvent.target;
          if (!clickTarget.closest("[data-id]")) {
            handleDeselect();
          }

          if (!selectionObj?.isEnabled) {
            setActiveTool(null);
            setSelectedShape(null);
          }
        });
      } catch (error) {
        console.error("Failed to initialize viewer:", error);
        setError(
          "Failed to initialize the viewer. Please check the console for details."
        );
      }
    };

    initializeViewer();

    return () => {
      if (viewerRef.current) {
        viewerRef.current.destroy();
      }
    };
  }, [currentImageUrl]);

  useEffect(() => {
    if (isViewerReady && viewerRef.current) {
      loadSavedAnnotations();
    }
  }, [isViewerReady, annotations]);

  useEffect(() => {
    fetch(`${API_URL}annotation/${currentSlideId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setAnnotations(data);
      })
      .catch((error) => {
        setError("Failed to fetch annotations. Please try again later.");
      });
  }, [currentSlideId]);

  const captureSnapshot = async () => {
    if (!viewerRef.current || isCapturing) return;

    setIsCapturing(true);
    const element = viewerRef.current.canvas;
    html2canvas(element).then((canvas) => {
      const dataURL = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
      const filename = `microscope-snapshot-${timestamp}.png`;
      link.href = dataURL;
      link.download = filename;
      link.click();
      setIsCapturing(false);
    });
  };

  const prepareAndCaptureSnapshot = () => {
    if (!viewerRef.current || isCapturing) return;
    viewerRef.current.forceRedraw();
    setTimeout(() => {
      captureSnapshot();
    }, 200);
  };

  const handleDeselect = () => {
    setSelectedAnnotation(null);
    setSelectedAnnotationData(null);
    resetAnnotationStyles();
  };

  const resetAnnotationStyles = () => {
    const elements = document.querySelectorAll("[data-id]");
    elements.forEach((el) => {
      el.classList.remove("annotation-selected");
      resetElementStyles(el);
    });
  };

  const resetElementStyles = (element) => {
    element.style.outline = "none";
    element.style.boxShadow = "none";

    const svg = element.querySelector("svg");
    if (svg) {
      const shape = svg.children[0];
      if (shape) {
        const originalStyles = element.dataset.originalStyles
          ? JSON.parse(element.dataset.originalStyles)
          : {
              strokeWidth: shape.getAttribute("stroke-width"),
              stroke: shape.getAttribute("stroke"),
              filter: "none",
            };

        shape.style.strokeWidth = originalStyles.strokeWidth;
        shape.style.stroke = originalStyles.stroke;
        shape.style.filter = originalStyles.filter;
      }
    }
  };

  const applyImageFilters = (contrastValue, brightnessValue) => {
    if (!viewerRef?.current) return;

    const viewer = viewerRef.current;
    if (!viewer.world || viewer.world.getItemCount() === 0) return;

    const tiledImage = viewer.world.getItemAt(0);
    if (!tiledImage) return;

    tiledImage.removeAllHandlers("clip");
    tiledImage.addHandler("clip", function (context) {
      context.filter = `contrast(${contrastValue * 100}%) brightness(${
        brightnessValue * 100
      }%)`;
      return true;
    });

    viewer.forceRedraw();
  };

  const handleContrastChange = (value) => {
    setContrast(value);
    applyImageFilters(value, brightness);
  };

  const handleBrightnessChange = (value) => {
    setBrightness(value);
    applyImageFilters(contrast, value);
  };

  const resetImageAdjustments = () => {
    setContrast(1);
    setBrightness(1);
    applyImageFilters(1, 1);
  };

  const rotateLeft = () => {
    if (!viewerRef.current) return;
    const newRotation = (rotation - 90) % 360;
    setRotation(newRotation);
    viewerRef.current.viewport.setRotation(newRotation);
  };

  const rotateRight = () => {
    if (!viewerRef.current) return;
    const newRotation = (rotation + 90) % 360;
    setRotation(newRotation);
    viewerRef.current.viewport.setRotation(newRotation);
  };

  const handlePreciseRotation = (degrees) => {
    if (!viewerRef.current) return;
    const newRotation = (rotation + degrees) % 360;
    setRotation(newRotation);
    viewerRef.current.viewport.setRotation(newRotation);
  };

  const loadSavedAnnotations = () => {
    if (!viewerRef.current || !annotations || !annotations.annotations) return;

    viewerRef.current.clearOverlays();

    annotations.annotations.forEach((annotation) => {
      if (!annotation || typeof annotation !== "object") {
        console.error("Invalid annotation:", annotation);
        return;
      }

      let boundingBox = annotation.boundingBox || annotation.rect;
      if (!boundingBox) {
        console.error("Annotation missing bounding box:", annotation);
        return;
      }

      let element = document.createElement("div");
      element.style.position = "absolute";
      element.style.pointerEvents = "all";
      element.dataset.id = annotation.id;
      element.style.width = boundingBox.width + "px";
      element.style.height = boundingBox.height + "px";

      element.addEventListener("click", (e) => {
        e.preventDefault();
        e.stopPropagation();
        selectAnnotation(annotation.id);
      });

      let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      svg.setAttribute("width", "100%");
      svg.setAttribute("height", "100%");
      svg.setAttribute("preserveAspectRatio", "none");
      svg.style.position = "absolute";
      svg.style.left = "0";
      svg.style.top = "0";
      svg.setAttribute("viewBox", "0 0 100 100");

      let shape;
      switch (annotation.type) {
        case "BrushShape":
          shape = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "polyline"
          );
          let pathData = annotation.points
            .filter(
              (point) =>
                point &&
                typeof point.x === "number" &&
                typeof point.y === "number"
            )
            .map((point) => {
              let relativeX = (point.x - boundingBox.x) / boundingBox.width;
              let relativeY = (point.y - boundingBox.y) / boundingBox.height;
              return `${relativeX * 100},${relativeY * 100}`;
            })
            .join(" ");
          shape.setAttribute("points", pathData);
          break;

        case "RectShape":
          shape = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "rect"
          );
          shape.setAttribute("x", "0");
          shape.setAttribute("y", "0");
          shape.setAttribute("width", "100");
          shape.setAttribute("height", "100");
          break;

        case "PolygonShape":
          shape = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "polygon"
          );
          let polygonData = annotation.points
            .filter(
              (point) =>
                point &&
                typeof point.x === "number" &&
                typeof point.y === "number"
            )
            .map((point) => {
              let relativeX = (point.x - boundingBox.x) / boundingBox.width;
              let relativeY = (point.y - boundingBox.y) / boundingBox.height;
              return `${relativeX * 100},${relativeY * 100}`;
            })
            .join(" ");
          shape.setAttribute("points", polygonData);
          break;

        case "LineShape":
          shape = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "line"
          );
          let relativeFromX =
            (annotation.from.x - boundingBox.x) / boundingBox.width;
          let relativeFromY =
            (annotation.from.y - boundingBox.y) / boundingBox.height;
          let relativeToX =
            (annotation.to.x - boundingBox.x) / boundingBox.width;
          let relativeToY =
            (annotation.to.y - boundingBox.y) / boundingBox.height;
          shape.setAttribute("x1", relativeFromX * 100);
          shape.setAttribute("y1", relativeFromY * 100);
          shape.setAttribute("x2", relativeToX * 100);
          shape.setAttribute("y2", relativeToY * 100);
          break;

        case "EllipseShape":
          shape = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "circle"
          );
          shape.setAttribute("cx", "50");
          shape.setAttribute("cy", "50");
          shape.setAttribute("r", "49");
          break;

        default:
          console.error("Unknown annotation type:", annotation.type);
          return;
      }

      shape.setAttribute("fill", annotation.style?.fill || "none");
      shape.setAttribute("stroke", annotation.style?.color || "rgb(200, 0, 0)");
      shape.setAttribute("stroke-width", annotation.style?.lineWidth || 2);
      shape.setAttribute("vector-effect", "non-scaling-stroke");

      svg.appendChild(shape);

      if (annotation.label) {
        let text = document.createElement("div");
        text.textContent = annotation.label;
        text.style.position = "absolute";
        text.style.top = "-15px";
        text.style.color = annotation.style?.color;
        text.style.fontSize = "0.8em";
        element.appendChild(text);
      }

      element.appendChild(svg);

      let rect = new OpenSeadragon.Rect(
        boundingBox.x,
        boundingBox.y,
        boundingBox.width,
        boundingBox.height
      );
      let viewportRect =
        viewerRef.current.viewport.imageToViewportRectangle(rect);

      viewerRef.current.addOverlay({
        element: element,
        location: viewportRect,
        placement: OpenSeadragon.Placement.TOP_LEFT,
      });
    });
  };

  const applyHighlightStyles = (shape, type) => {
    const baseStrokeWidth = parseFloat(
      shape.getAttribute("stroke-width") || "2"
    );
    const highlightStrokeWidth = baseStrokeWidth * 1.5;

    switch (type) {
      case "RectShape":
        shape.style.strokeWidth = `${highlightStrokeWidth}px`;
        shape.style.stroke = "#FFD700";
        shape.style.filter = "drop-shadow(0 0 3px rgba(255, 215, 0, 0.5))";
        break;
      case "EllipseShape":
        shape.style.strokeWidth = `${highlightStrokeWidth}px`;
        shape.style.stroke = "#FFD700";
        shape.style.filter = "drop-shadow(0 0 4px rgba(255, 215, 0, 0.6))";
        break;
      case "LineShape":
        shape.style.strokeWidth = `${highlightStrokeWidth + 1}px`;
        shape.style.stroke = "#FFD700";
        shape.style.filter = "drop-shadow(0 0 2px rgba(255, 215, 0, 0.7))";
        break;
      default:
        shape.style.strokeWidth = `${highlightStrokeWidth}px`;
        shape.style.stroke = "#FFD700";
        shape.style.filter = "drop-shadow(0 0 3px rgba(255, 215, 0, 0.5))";
    }
  };

  const selectAnnotation = (id) => {
    const annotationData = annotations.annotations.find(
      (anno) => anno.id === id
    );

    setSelectedAnnotation(id);
    setSelectedAnnotationData(annotationData);

    resetAnnotationStyles();

    const selectedElement = document.querySelector(`[data-id="${id}"]`);
    if (selectedElement) {
      selectedElement.classList.add("annotation-selected");

      const svg = selectedElement.querySelector("svg");
      if (svg) {
        const shape = svg.children[0];
        if (shape) {
          if (!selectedElement.dataset.originalStyles) {
            const originalStyles = {
              strokeWidth: shape.getAttribute("stroke-width"),
              stroke: shape.getAttribute("stroke"),
              filter: shape.style.filter,
            };
            selectedElement.dataset.originalStyles =
              JSON.stringify(originalStyles);
          }
          applyHighlightStyles(shape, annotationData?.type);
        }
      }
    }
  };

  const onSelect = (rect, shape) => {
    if (!shape) {
      console.error("Shape is undefined in onSelect function");
      return;
    }

    let shapeType = shape.constructor.name;
    if (!(shapeType in shapeNames)) {
      shapeType =
        viewerRef.current.selectionHandler.frontCanvas.drawer.drawerActiveShape;
    }

    // Clear the temporary drawing immediately
    if (viewerRef.current?.selectionHandler?.frontCanvas) {
      viewerRef.current.selectionHandler.frontCanvas.clear();
    }

    // Disable the selection after drawing
    if (selectionObj) {
      selectionObj.disable();
      setIsSelecting(false);
    }

    const calculateBoundingBox = (points) => {
      if (!points || points.length === 0) return null;
      let minX = Infinity,
        minY = Infinity,
        maxX = -Infinity,
        maxY = -Infinity;
      points.forEach((point) => {
        minX = Math.min(minX, point.x);
        minY = Math.min(minY, point.y);
        maxX = Math.max(maxX, point.x);
        maxY = Math.max(maxY, point.y);
      });
      return {
        x: minX,
        y: minY,
        width: maxX - minX,
        height: maxY - minY,
      };
    };

    const annotationStyle = {
      lineWidth: getLineWidthValue(lineWidth),
      color: drawColor,
      fill: "none",
    };

    let newAnnotation;

    switch (shapeType) {
      case "BrushShape":
        newAnnotation = {
          id: Date.now().toString(),
          type: "BrushShape",
          points: Array.isArray(shape.points)
            ? shape.points.map((point) => ({ x: point.x, y: point.y }))
            : [],
          boundingBox: shape.getBoundingBox
            ? shape.getBoundingBox()
            : calculateBoundingBox(shape.points),
          style: annotationStyle,
          label: labelInput,
        };
        break;

      case "RectShape":
        newAnnotation = {
          id: Date.now().toString(),
          type: "RectShape",
          rect: {
            x: rect.x,
            y: rect.y,
            width: rect.width,
            height: rect.height,
          },
          style: annotationStyle,
          label: labelInput,
        };
        break;

      case "PolygonShape":
        const points = Array.isArray(shape.points)
          ? shape.points.map((point) => ({ x: point.x, y: point.y }))
          : [];
        newAnnotation = {
          id: Date.now().toString(),
          type: "PolygonShape",
          points: points,
          boundingBox: calculateBoundingBox(points),
          style: annotationStyle,
          label: labelInput,
        };
        break;

      case "LineShape":
        newAnnotation = {
          id: Date.now().toString(),
          type: "LineShape",
          rect: {
            x: rect.x,
            y: rect.y,
            width: rect.width,
            height: rect.height,
          },
          from: { x: shape.from.x, y: shape.from.y },
          to: { x: shape.to.x, y: shape.to.y },
          style: annotationStyle,
          label: labelInput,
        };
        break;

      case "EllipseShape":
        newAnnotation = {
          id: Date.now().toString(),
          type: "EllipseShape",
          rect: {
            x: rect.x,
            y: rect.y,
            width: rect.width,
            height: rect.height,
          },
          style: annotationStyle,
          label: labelInput,
        };
        break;

      default:
        console.warn(`Unhandled shape type: ${shapeType}`);
        return;
    }

    setAnnotations((prevAnnotations) => {
      const updatedAnnotations = {
        ...prevAnnotations,
        annotations: [...prevAnnotations.annotations, newAnnotation],
      };
      saveAnnotations(updatedAnnotations);
      return updatedAnnotations;
    });

    setLabelInput("");
    setActiveTool(null);
    setSelectedShape(null);
  };

  const setShape = (shape) => {
    if (!viewerRef.current || !shapeNames) return;

    // Clear any existing drawings first
    if (viewerRef.current?.selectionHandler?.frontCanvas) {
      viewerRef.current.selectionHandler.frontCanvas.clear();
    }

    // If the same tool is selected again, disable it
    if (activeTool === shape) {
      setActiveTool(null);
      setSelectedShape(null);
      if (selectionObj) {
        selectionObj.disable();
        setIsSelecting(false);
      }
      if (viewerRef.current?.selectionHandler?.frontCanvas?.canvas) {
        viewerRef.current.selectionHandler.frontCanvas.canvas.classList.remove(
          "is-selecting"
        );
      }
      return;
    }

    // Disable any existing selection
    if (selectionObj?.isEnabled) {
      selectionObj.disable();
    }

    viewerRef.current.initSelection();

    const frontCanvas = viewerRef.current.selectionHandler.frontCanvas;
    if (shape === "LineShape") {
      frontCanvas.drawer.addShape("LineShape", LineShape);
    } else if (shape === "EllipseShape") {
      frontCanvas.drawer.addShape("EllipseShape", EllipseShape);
    }

    frontCanvas.drawer.drawOptions = {
      color: hexToRGB(drawColor),
      lineWidth: getLineWidthValue(lineWidth),
      fill: "rgba(220,220,220,0.2)",
    };

    frontCanvas.drawer.setDrawerShape(shape);

    const newSelectionObj = viewerRef.current.selection({
      onSelection: onSelect,
      keep: false, // Changed to false to prevent duplicate drawings
      restrictToImage: true,
      allowRotation: false,
      showConfirmDenyButtons: false,
      styleConfirmDenyButtons: false,
      keyboardShortcut: false,
      onSelectionDraw: (rect) => {
        frontCanvas.clear();
        frontCanvas.drawer.drawShape(rect);
      },
      onClick: (e) => {
        if (e.originalEvent.button === 0) {
          const rect = frontCanvas.drawer.currentRect;
          if (rect) {
            const shape = frontCanvas.drawer.currentShape;
            onSelect(rect, shape);
          }
        }
      },
    });

    newSelectionObj.enable();
    setSelectionObj(newSelectionObj);
    setIsSelecting(true);
    setActiveTool(shape);
    setSelectedShape(shape);

    if (frontCanvas.canvas) {
      frontCanvas.canvas.classList.add("is-selecting");
    }
  };

  const toggleGrid = () => {
    const gridElement = document.querySelector("[data-id='grid']");
    if (gridElement) {
      viewerRef.current.removeOverlay(gridElement);
      return;
    }

    var viewerWidth = viewerRef.current.world.getItemAt(0).source.width;
    var viewerHeight = viewerRef.current.world.getItemAt(0).source.height;
    const gridSize = viewerWidth / 10;

    var numHorizontalLines = Math.ceil(viewerHeight / gridSize);
    var numVerticalLines = Math.ceil(viewerWidth / gridSize);

    let element = document.createElement("div");
    element.style.position = "absolute";
    element.style.pointerEvents = "none";
    element.dataset.id = "grid";
    element.style.width = viewerWidth + "px";
    element.style.height = viewerHeight + "px";

    let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svg.setAttribute("width", "100%");
    svg.setAttribute("height", "100%");
    svg.setAttribute("preserveAspectRatio", "none");
    svg.setAttribute("viewBox", `0 0 ${viewerWidth} ${viewerHeight}`);

    // Draw horizontal lines
    for (var i = 0; i <= numHorizontalLines; i++) {
      var line = document.createElementNS("http://www.w3.org/2000/svg", "line");
      line.setAttribute("x1", "0");
      line.setAttribute("y1", i * gridSize);
      line.setAttribute("x2", viewerWidth);
      line.setAttribute("y2", i * gridSize);
      line.setAttribute("stroke", "rgba(0, 0, 0, 0.5)");
      line.setAttribute("stroke-width", "1");
      line.setAttribute("vector-effect", "non-scaling-stroke");
      svg.appendChild(line);
    }

    // Draw vertical lines
    for (var i = 0; i <= numVerticalLines; i++) {
      var line = document.createElementNS("http://www.w3.org/2000/svg", "line");
      line.setAttribute("x1", i * gridSize);
      line.setAttribute("y1", "0");
      line.setAttribute("x2", i * gridSize);
      line.setAttribute("y2", viewerHeight);
      line.setAttribute("stroke", "rgba(0, 0, 0, 0.5)");
      line.setAttribute("stroke-width", "1");
      line.setAttribute("vector-effect", "non-scaling-stroke");
      svg.appendChild(line);
    }

    element.appendChild(svg);

    let rect = new OpenSeadragon.Rect(0, 0, viewerWidth, viewerHeight);
    let viewportRect =
      viewerRef.current.viewport.imageToViewportRectangle(rect);
    viewerRef.current.addOverlay({
      element: element,
      location: viewportRect,
      placement: OpenSeadragon.Placement.TOP_LEFT,
    });
  };

  const hexToRGB = (hexColor) => {
    const r = parseInt(hexColor.substr(1, 2), 16);
    const g = parseInt(hexColor.substr(3, 2), 16);
    const b = parseInt(hexColor.substr(5, 2), 16);
    return `rgb(${r},${g},${b})`;
  };

  const handleSelectionTool = () => {
    setSelectionActive(!isSelectionActive);
    if (activeTool) {
      setActiveTool(null);
      setSelectedShape(null);
      if (selectionObj) {
        selectionObj.disable();
        setIsSelecting(false);
      }
    }
  };

  const eraseAllAnnotations = () => {
    if (viewerRef.current) {
      viewerRef.current.clearOverlays();
      const emptyAnnotations = { annotations: [] };
      setAnnotations(emptyAnnotations);
      setSelectedAnnotation(null);
      saveAnnotations(emptyAnnotations);
    }
  };

  const deleteSelectedAnnotation = () => {
    if (selectedAnnotation) {
      setAnnotations((prevAnnotations) => {
        const updatedAnnotations = {
          ...prevAnnotations,
          annotations: prevAnnotations.annotations.filter(
            (anno) => anno.id !== selectedAnnotation
          ),
        };
        saveAnnotations(updatedAnnotations);
        return updatedAnnotations;
      });
      setSelectedAnnotation(null);
      loadSavedAnnotations();
    }
  };

  const handleZoom = (zoomLevel) => {
    if (viewerRef.current) {
      viewerRef.current.viewport.zoomTo(zoomLevel);
      setCurrentZoom(zoomLevel);
    }
  };

  const getLineWidthValue = (option) => {
    switch (option) {
      case "extra-thin":
        return 1;
      case "thin":
        return 2;
      case "normal":
        return 3;
      case "thick":
        return 5;
      default:
        return 3;
    }
  };

  const saveAnnotations = (annotationsToSave) => {
    fetch(`${API_URL}annotation/${currentSlideId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(annotationsToSave),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Annotations saved successfully:", data);
      })
      .catch((error) => {
        console.error("Error saving annotations:", error);
        setError("Failed to save annotations. Changes may be lost.");
      });
  };
  return (
    <div className="microscope-slide-viewer">
      <div className="workspace-header"></div>
      <div className="annotation-viewer">
        <div className="annotation-div">
          <div className="annotation-div1">
            <div
              className=""
              style={{ cursor: "pointer" }}
              onClick={() => {
                setOpenWorkspace(!openWorkspace);
              }}
            >
              <ArrowBack />
            </div>
            <div
              className={`tool-icon ${isSelectionActive ? "selected" : ""}`}
              onClick={handleSelectionTool}
              title="Selection Tool"
            >
              <HighlightAlt />
            </div>

            <div
              className={`tool-icon ${showContrastPanel ? "selected" : ""}`}
              onClick={() => setShowContrastPanel(!showContrastPanel)}
              title="Adjust Contrast & Brightness"
            >
              <ContrastOutlined />
            </div>

            {showContrastPanel && (
              <ContrastPanel
                contrast={contrast}
                brightness={brightness}
                onContrastChange={handleContrastChange}
                onBrightnessChange={handleBrightnessChange}
                onReset={resetImageAdjustments}
                viewerRef={viewerRef}
              />
            )}

            <div
              className={`tool-icon ${
                selectedShape === shapeNames.RectShape ? "selected" : ""
              }`}
              onClick={() => setShape(shapeNames.RectShape)}
              title="Rectangle Tool"
            >
              <RectangleOutlined />
            </div>
            <div
              className={`tool-icon ${
                selectedShape === "EllipseShape" ? "selected" : ""
              }`}
              onClick={() => setShape("EllipseShape")}
              title="Circle Tool"
            >
              <Circle className="workspace-image" />
            </div>
            <div
              className={`tool-icon ${
                selectedShape === shapeNames.BrushShape ? "selected" : ""
              }`}
              onClick={() => setShape(shapeNames.BrushShape)}
              title="Brush Tool"
            >
              <Pencil />
            </div>
            <div
              className={`tool-icon ${
                selectedShape === "LineShape" ? "selected" : ""
              }`}
              onClick={() => setShape("LineShape")}
              title="Line Tool"
            >
              <Minus className="workspace-image" />
            </div>
            <div
              className={`tool-icon ${
                selectedShape === shapeNames.PolygonShape ? "selected" : ""
              }`}
              onClick={() => setShape(shapeNames.PolygonShape)}
              title="Polygon Tool"
            >
              <PolylineOutlined />
            </div>

            <div
              className="tool-icon"
              onClick={prepareAndCaptureSnapshot}
              style={{ opacity: isCapturing ? 0.5 : 1 }}
              title="Capture Snapshot"
            >
              <PhotoCamera />
            </div>
            <div className="tool-icon" onClick={toggleGrid} title="Toggle Grid">
              <Grid4x4Outlined />
            </div>
            <div
              className="tool-icon"
              onClick={eraseAllAnnotations}
              title="Clear All Annotations"
            >
              <DeleteOutline />
            </div>

            <div
              className="rotation-controls"
              style={{ display: "flex", gap: "8px", alignItems: "center" }}
            >
              <div
                className="tool-icon"
                onClick={rotateLeft}
                title="Rotate Left 90°"
              >
                <RotateLeft />
              </div>
              <div
                className="tool-icon"
                onClick={rotateRight}
                title="Rotate Right 90°"
              >
                <RotateRight />
              </div>
              <div className="label-div">
                Precise Rotation:
                <input
                  type="number"
                  min="-360"
                  max="360"
                  step="1"
                  value={rotation}
                  onChange={(e) => {
                    const degrees = parseFloat(e.target.value) - rotation;
                    handlePreciseRotation(degrees);
                  }}
                  style={{ width: "60px", marginLeft: "4px" }}
                />
                °
              </div>
            </div>

            <div className="label-div">
              Line Width:
              <select
                id="lineWidth"
                value={lineWidth}
                onChange={(e) => setLineWidth(e.target.value)}
              >
                <option value="extra-thin">Extra thin</option>
                <option value="thin">Thin</option>
                <option value="normal">Normal</option>
                <option value="thick">Thick</option>
              </select>
            </div>

            <div className="label-div">
              Color:
              <input
                type="color"
                id="drawColor"
                value={drawColor}
                onChange={(e) => setDrawColor(e.target.value)}
              />
            </div>

            <div className="label-div">
              Label:
              <input
                type="text"
                id="labelInput"
                value={labelInput}
                onChange={(e) => setLabelInput(e.target.value)}
                placeholder="Enter label"
              />
            </div>
          </div>
        </div>

        <div className="viewer-container">
          <div
            id="viewer"
            style={{ width: "100%", height: 575 }}
            className="openseadragon-viewer"
          ></div>
          {error && <div className="error-message">{error}</div>}
          {isCapturing && (
            <div className="capturing-overlay">Capturing snapshot...</div>
          )}
          {selectedAnnotation && (
            <div className="delete-annotation">
              <button onClick={deleteSelectedAnnotation}>
                Delete Selected Annotation
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="controls">
        <div className="zoom-controls">
          <button
            className={`button-zoom ${currentZoom === 0.5 ? "selected" : ""}`}
            onClick={() => handleZoom(0.5)}
          >
            <FitScreen />
          </button>
          <button
            className={`button-zoom ${currentZoom === 1 ? "selected" : ""}`}
            onClick={() => handleZoom(1)}
          >
            2x
          </button>
          <button
            className={`button-zoom ${currentZoom === 2.5 ? "selected" : ""}`}
            onClick={() => handleZoom(2.5)}
          >
            5x
          </button>
          <button
            className={`button-zoom ${currentZoom === 5 ? "selected" : ""}`}
            onClick={() => handleZoom(5)}
          >
            10x
          </button>
          <button
            className={`button-zoom ${currentZoom === 10 ? "selected" : ""}`}
            onClick={() => handleZoom(10)}
          >
            20x
          </button>
          <button
            className={`button-zoom ${currentZoom === 20 ? "selected" : ""}`}
            onClick={() => handleZoom(20)}
          >
            40x
          </button>
          <button
            className={`button-zoom ${currentZoom === 30 ? "selected" : ""}`}
            onClick={() => handleZoom(30)}
          >
            60x
          </button>
        </div>
      </div>
    </div>
  );
};

export default MicroscopeSlideViewer;
