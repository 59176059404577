import React, { useEffect, useState } from "react";
import "./style.css";
import {
  ArrowBack,
  Delete,
  DeleteOutline,
  FlagOutlined,
  RemoveRedEyeOutlined,
  Search,
  ShareOutlined,
  StarBorderOutlined,
} from "@mui/icons-material";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Checkbox,
  Pagination,
  Stack,
  PaginationItem,
  CircularProgress,
  Box,
  Typography,
} from "@mui/material";
import Swal from "sweetalert2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MicroscopeSlideViewer from "../../test/index";
import UploadSlideModal from "../../Components/UploadSlideModal/UploadSlideModal";
import TileGenProgressBar from "../../Components/TileGenProgressBar";
import { API_URL } from "../../env";
import ShareModal from "../../Components/Share/ShareModal";

const SlideArchive = ({ currentCaseId, setopenWorklist, openWorklist }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [cases, setCases] = useState([]);
  const [open, setOpen] = useState(false);
  const [openWorkspace, setOpenWorkspace] = useState(false);
  const [selectedCases, setSelectedCases] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentSlideId, setCurrentSlideId] = useState(null);
  const [currentImageUrl, setCurrentImageUrl] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);
  const [tileGenProgress, setTileGenProgress] = useState(0);
  const [shareModal, setShareModal] = useState(false);
  const [selectedSlideForShare, setSelectedSlideForShare] = useState(null);

  useEffect(() => {
    fetch(`${API_URL}slides/${currentCaseId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCases(data);
        setTotalPages(data.total_pages);
      })
      .catch((error) => {
        console.error("Error fetching cases:", error);
      });
  }, [refresh, page, currentCaseId]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSpecializationChange = (e) => {
    setSpecialization(e.target.value);
  };

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  const handleUpload = () => {
    setOpen(true);
    setUploadProgress(0);
    setTimeLeft(0);
    setTileGenProgress(0);
  };

  const handleSelectAll = (e) => {
    const newSelectedCases = {};
    if (e.target.checked) {
      cases.forEach((caseItem) => {
        newSelectedCases[caseItem.SlideId] = true;
      });
    }
    setSelectedCases(newSelectedCases);
  };

  const handleSelectCase = (SlideId) => {
    setSelectedCases((prevSelected) => ({
      ...prevSelected,
      [SlideId]: !prevSelected[SlideId],
    }));
  };

  const isCaseSelected = (SlideId) => !!selectedCases[SlideId];

  const isAllSelected =
    cases.length > 0 && Object.keys(selectedCases).length === cases.length;

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRowDoubleClick = (slideId, imageUrl) => {
    setCurrentSlideId(slideId);
    setCurrentImageUrl(imageUrl);
    setOpenWorkspace(true);
  };

  const handleViewDetailsClick = (slideId, imageUrl) => {
    setCurrentSlideId(slideId);
    setCurrentImageUrl(imageUrl);
    setOpenWorkspace(true);
  };

  const deleteCase = async (caseId) => {
    try {
      const response = await fetch(`${API_URL}slides/${caseId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.ok) {
        const result = await response.json();
        setRefresh(true);
        return true;
      } else {
        console.error("Failed to delete case");
        return false;
      }
    } catch (error) {
      console.error("Error deleting case:", error);
      return false;
    }
  };

  const handleDeleteCase = async (caseId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const isDeleted = await deleteCase(caseId);
        if (isDeleted) {
          Swal.fire("Deleted!", "Your case has been deleted.", "success");
        }
        setRefresh(!refresh);
      }
    });
  };
  const shareModalClick = (slideId) => {
    setSelectedSlideForShare(slideId);
    setShareModal(true);
  };
  return (
    <>
      {openWorkspace ? (
        <MicroscopeSlideViewer
          currentSlideId={currentSlideId}
          openWorkspace={openWorkspace}
          setOpenWorkspace={setOpenWorkspace}
          currentImageUrl={currentImageUrl}
        />
      ) : (
        <div className="worklist-container">
          <div className="worklist-header">
            <div
              className=""
              style={{ cursor: "pointer" }}
              onClick={() => {
                setopenWorklist(!openWorklist);
              }}
            >
              <ArrowBack />
            </div>
            <div className="workspace-header-title">My Workspace</div>
            <div className="workSpace-filterBox">
              <div className="search-worklist">
                <input
                  style={{ border: "none" }}
                  className="search-bar"
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <div>
                  <Search />
                </div>
              </div>

              <div
                className="create-upload"
                onClick={() => {
                  handleUpload();
                }}
              >
                Upload
              </div>
            </div>
          </div>
          <div className="worklist-head">
            <div className="delete-btn">
              <div style={{ display: "flex", alignItems: "center" }}>
                <Delete fontSize="12px" />
              </div>
              <div>Delete</div>
            </div>
          </div>
          <div className="table-container">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      padding: "0px 0px 5px 0px",
                      fontSize: 12,
                      fontWeight: "600",
                      borderBottom: "none",
                    }}
                  >
                    <Checkbox
                      className="small-checkbox"
                      indeterminate={
                        Object.keys(selectedCases).length > 0 && !isAllSelected
                      }
                      checked={isAllSelected}
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell className="table-head">Thumbnail</TableCell>
                  <TableCell className="table-head">Name</TableCell>
                  <TableCell className="table-head">Tags</TableCell>
                  <TableCell className="table-head">Shared</TableCell>
                  <TableCell className="table-head">Size</TableCell>
                  <TableCell
                    style={{
                      padding: "0px 5px 5px 0px",
                      textAlign: "end",
                      fontSize: 12,
                      fontWeight: "600",
                      borderBottom: "none",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cases?.map((row, index) => (
                  <React.Fragment key={index}>
                    <TableRow
                      className="table-row"
                      onDoubleClick={() =>
                        handleRowDoubleClick(row.SlideId, row.ImageUrl)
                      }
                    >
                      <TableCell
                        style={{
                          borderTopLeftRadius: 12,
                          borderBottomLeftRadius: 12,
                        }}
                        className="table-cell"
                      >
                        <Checkbox
                          className="small-checkbox"
                          checked={isCaseSelected(row.SlideId)}
                          onChange={() => handleSelectCase(row.SlideId)}
                        />
                      </TableCell>
                      <TableCell className="table-cell">
                        <img
                          style={{
                            margin: 0,
                            width: 90,
                            height: 60,
                            borderRadius: 8,
                          }}
                          src={`https://path-image.s3.amazonaws.com/${row.Thumbnail}`}
                          alt={row.SlideName}
                        />
                      </TableCell>
                      <TableCell className="table-cell">
                        {row.SlideName}
                      </TableCell>
                      <TableCell className="table-cell">
                        <div className="tags">
                          {row.Tags.map((tag, idx) => (
                            <div key={idx} className="workspace-tag">
                              {tag}
                            </div>
                          ))}
                        </div>
                      </TableCell>
                      <TableCell className="table-cell">
                        {row.Shared.length > 0 ? "Yes" : "No"}
                      </TableCell>
                      <TableCell className="table-cell">
                        {parseFloat(row.Size).toFixed(2) + " Mb"}
                      </TableCell>
                      <TableCell
                        style={{
                          borderTopRightRadius: 12,
                          borderBottomRightRadius: 12,
                          textAlign: "end",
                          width: 230,
                        }}
                        className="table-cell"
                      >
                        <IconButton className="iconButton">
                          <StarBorderOutlined className="verifiedIcon" />
                        </IconButton>
                        <IconButton className="iconButton">
                          <FlagOutlined className="flagIcon" />
                        </IconButton>
                        <IconButton className="iconButton">
                          <ShareOutlined
                            onClick={() => {
                              shareModalClick(row.SlideId);
                            }}
                            className="shareIcon"
                          />
                        </IconButton>
                        <IconButton className="iconButton">
                          <RemoveRedEyeOutlined
                            className="removeRedEyeIcon"
                            onClick={() =>
                              handleViewDetailsClick(row.SlideId, row.ImageUrl)
                            }
                          />
                        </IconButton>
                        <IconButton
                          className="iconButton"
                          onClick={() => handleDeleteCase(row.SlideId)}
                        >
                          <DeleteOutline className="deleteIcon" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <div style={{ height: "4px" }}></div>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Stack spacing={2}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                )}
              />
            </Stack>
          </div>
          {open && (
            <UploadSlideModal
              refresh={refresh}
              setRefresh={setRefresh}
              open={open}
              setOpen={setOpen}
              currentCaseId={currentCaseId}
              setUploadProgress={setUploadProgress}
              setTimeLeft={setTimeLeft}
              setTileGenProgress={setTileGenProgress}
            />
          )}

          {uploadProgress > 0 && uploadProgress < 100 && (
            <Box
              sx={{
                position: "fixed",
                bottom: 16,
                right: 16,
                display: "flex",
                alignItems: "center",
                bgcolor: "background.paper",
                boxShadow: 3,
                width: "164px",
                p: 1,
              }}
            >
              <CircularProgress
                variant="determinate"
                value={uploadProgress}
                size={40}
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  width: "297px",
                }}
              >
                <Typography
                  variant="caption"
                  component="div"
                  color="text.secondary"
                >
                  {`${Math.round(uploadProgress)}%`}
                </Typography>
              </Box>
              <Typography variant="caption" sx={{ ml: 2 }}>
                {timeLeft > 0 ? `${timeLeft}s left` : "Calculating..."}
              </Typography>
            </Box>
          )}

          {tileGenProgress > 0 && tileGenProgress < 100 && (
            <TileGenProgressBar progress={tileGenProgress} />
          )}
          {shareModal && (
            <ShareModal
              shareModal={shareModal}
              slideId={selectedSlideForShare}
              setShareModal={setShareModal}
              onClose={() => setShareModal(false)}
            />
          )}
        </div>
      )}
    </>
  );
};

export default SlideArchive;
