import { Point, Rect } from "openseadragon";
import { BaseShape } from "openseadragon-select-plugin";

export class EllipseShape extends BaseShape {
  topLeft: Point = new Point();
	bottomLeft: Point;

	get boundingBox() {
		const x = Math.min(
			this.topLeft.x,
			this.bottomLeft.x,
		);
		const y = Math.min(
			this.topLeft.y,
			this.bottomLeft.y,
		);
		const x1 = Math.max(
			this.topLeft.x,
			this.bottomLeft.x,
		);
		const y1 = Math.max(
			this.topLeft.y,
			this.bottomLeft.y,
		);
		return new Rect(x, y, x1 - x, y1 - y);
	}

	toPath2D() {
		if (!this.bottomLeft) {
			return new Path2D();
		}
		const { x, y } = this.toViewerCoords(this.topLeft);
		const { x: x1, y: y1 } = this.toViewerCoords(
			this.bottomLeft,
		);

    const boxWidth = x1 - x
    const boxHeight = y1 - y

    // Calculate the center
    const centerX = x + boxWidth / 2;
    const centerY = y + boxHeight / 2;

    // Calculate the radii
    const radiusX = Math.abs(boxWidth / 2);
    const radiusY = Math.abs(boxHeight / 2);

    const path2d = new Path2D();
    path2d.ellipse(centerX, centerY, radiusX, radiusY, 0, 0, 2 * Math.PI);
		return path2d;
	}

	onMouseDown(point: Point): void {
		if (!this.isDrawing) {
			return;
		}
		this.topLeft = point.clone();
		this.bottomLeft = point.clone();
	}
	onMouseMove(point: Point): void {
		if (!this.isDrawing) {
			return;
		}
		this.bottomLeft = point.clone();
	}
	onMouseUp(point?: Point): void {
		if (!this.isDrawing) {
			return;
		}
		this.finishDrawing();
		if (point) {
			this.onMouseMove(point);
		}
	}
}
