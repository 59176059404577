import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import { API_URL } from "../../env";
import { Upload } from "@mui/icons-material";
import { CloudUpload } from "lucide-react";
import { Button } from "@mui/material";

const sharedOptions = [
  { value: "1", label: "Yes" },
  { value: "2", label: "No" },
];

export default function UploadSlideModal({
  open,
  setOpen,
  setRefresh,
  currentCaseId,
  setUploadProgress,
  setTimeLeft,
}) {
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      CaseId: currentCaseId,
      SlideName: "",
      Tags: "",
      Shared: 1,
      file: null,
    },
  });

  const [fileName, setFileName] = useState("");

  const handleClose = () => setOpen(false);

  const TileGeneration = (imageUrl) => {
    fetch("https://tilegen.genesysailabs.com/generate-tiles", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        "s3-bucket-name": "path-image",
        "s3-src": imageUrl,
        "s3-dest": `${currentCaseId}/wsi-dzi`,
        "s3-thump": `${currentCaseId}`,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Tile generation successful:", data);
      })
      .catch((error) => {
        console.error("Error in tile generation:", error);
      });
  };

  const onSubmit = (formData) => {
    const formDataWithFile = new FormData();
    Object.keys(formData).forEach((key) => {
      formDataWithFile.append(key, formData[key]);
    });
    handleClose();

    const xhr = new XMLHttpRequest();
    xhr.open("POST", `${API_URL}slides/`, true);
    xhr.setRequestHeader(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    const startTime = Date.now();

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = (event.loaded / event.total) * 100;
        setUploadProgress(percentComplete);

        const uploadSpeed = event.loaded / (Date.now() - startTime);
        const timeLeft = (event.total - event.loaded) / uploadSpeed / 1000; // in seconds
        setTimeLeft(Math.round(timeLeft));
      }
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        const data = JSON.parse(xhr.responseText);
        console.log("Success:", data.ImageUrl);
        TileGeneration(data.ImageUrl);
        reset();
        setRefresh((prev) => !prev);
        handleClose();
      } else {
        console.error("Error:", xhr.statusText);
      }
    };

    xhr.onerror = () => {
      console.error("Error:", xhr.statusText);
    };

    xhr.send(formDataWithFile);
  };

  const renderTextField = (
    name,
    label,
    type = "text",
    select = false,
    options = []
  ) => (
    <Grid item xs={12}>
      <label className="label">{label}*</label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField
            fullWidth
            type={type}
            variant="outlined"
            sx={{
              backgroundColor: "white",
              borderRadius: "12px",
              border: "none",
              height: "40px",
              "& .MuiInputBase-root": {
                height: "100%",
              },
            }}
            select={select}
            {...field}
            required
          >
            {select &&
              options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </TextField>
        )}
      />
    </Grid>
  );

  const renderFileField = (name, label) => (
    <Grid item xs={12}>
      <label className="label">{label}*</label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <div>
            <input
              type="file"
              id="file-upload"
              accept=".svs,.dcm,.dicom,.tif,.tiff,.jpg,.jpeg,.png"
              style={{ display: "none" }}
              onChange={(e) => {
                field.onChange(e.target.files[0]);
                setFileName(e.target.files[0]?.name || "");
              }}
              required
            />
            <label htmlFor="file-upload">
              <Button
                variant="contained"
                component="span"
                startIcon={<CloudUpload />}
                sx={{
                  backgroundColor: "white",
                  color: "#333",
                  "&:hover": {
                    backgroundColor: "#e0e0e0",
                  },
                }}
              >
                Choose File
              </Button>
            </label>
            {fileName && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                Selected file: {fileName}
              </Typography>
            )}
          </div>
        )}
      />
    </Grid>
  );

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className="modal-box-upload" sx={{ p: 4 }}>
            <Typography
              style={{ textAlign: "center", marginBottom: 20 }}
              id="transition-modal-title"
              variant="h5"
              component="h2"
            >
              Upload Slide
            </Typography>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid container spacing={4}>
                {renderTextField("SlideName", "Slide Name")}
                {renderTextField("Tags", "Tags")}
                {renderTextField(
                  "Shared",
                  "Shared",
                  "text",
                  true,
                  sharedOptions
                )}
                {renderFileField("file", "File")}
              </Grid>
            </Box>
            <div className="submit-div">
              <div className="cancel-div" onClick={handleClose}>
                Cancel
              </div>
              <div className="save-div" onClick={handleSubmit(onSubmit)}>
                Save
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
