import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const URLRedirectHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkAndRedirect = () => {
      const currentURL = window.location.href;

      const isSharedLink = currentURL.includes(
        "pathlite.genesysailabs.com/share/"
      );

      if (isSharedLink && location.pathname !== "/sharelogin") {
        const shareId = currentURL.split("/share/")[1];

        navigate(`/sharelogin?id=${shareId}`);
      }
    };

    checkAndRedirect();
  }, [navigate, location]);

  return null; // This component doesn't render anything
};
export default URLRedirectHandler;
