import React from "react";
import LogoSvg from "../../Assets/nav-logo.svg";
import LogoSvg1 from "../../Assets/nav-logo1.svg";
import LogoSvg2 from "../../Assets/Ellipse 1.png";
import "./style.css";
import { Logout } from "@mui/icons-material";
import { API_URL } from "../../env";

const Navbar = () => {
  const handleLogout = async () => {
    try {
      const response = await fetch(`${API_URL}auth/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Assuming you store the token in localStorage
        },
      });

      if (response.ok) {
        localStorage.removeItem("token");

        window.location.href = "/";
      } else {
        console.error("Logout failed");
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return (
    <div>
      <div className="navbar-container">
        <div className="navbar-div">
          <div className="nav-image">
            <img src={LogoSvg} alt="nav-logo" className="nav-logo" />
          </div>
          <div className="nav-img">
            <img src={LogoSvg1} alt="nav-logo" className="nav-logo" />
          </div>
        </div>
        <div className="nav-item">
          <div className="nav-space">
            <div className="nav">12/50</div>
            <div className="nav-total">Total Space Used</div>
          </div>
          <div className="avatar">
            <img src={LogoSvg2} alt="User avatar" />
          </div>
          <div className="avatar-div">
            <div className="avatar-name">Joshua Joseph</div>
            <div className="avatar-sub">user</div>
          </div>
          <div className="logout-icon" onClick={handleLogout}>
            <Logout color="White" size={24} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
